import React from "react";
import { IntlProvider } from "react-intl";
import { ThemeProvider } from "styled-components";
import flatten from "flat";
import merge from "lodash/merge";
import enAboutContent from "../../content/home/about/en.json";
import enClientsContent from "../../content/home/clients/en.json";
import enContactContent from "../../content/home/contact/en.json";
import enServicesContent from "../../content/home/services/en.json";
import enTeamContent from "../../content/home/team/en.json";
import enValuesContent from "../../content/home/values/en.json";
import nlAboutContent from "../../content/home/about/nl.json";
import nlClientsContent from "../../content/home/clients/nl.json";
import nlContactContent from "../../content/home/contact/nl.json";
import nlServicesContent from "../../content/home/services/nl.json";
import nlTeamContent from "../../content/home/team/nl.json";
import nlValuesContent from "../../content/home/values/nl.json";
import enCommonContent from "../../content/common/en.json";
import nlCommonContent from "../../content/common/nl.json";
import theme from "../../theme";
import Locale from "../../types/Locale";
import GlobalHead from "../../components/GlobalHead";
import GlobalStyle from "../../components/GlobalStyle";
import Home from "../../components/Home";
import ImagesContext from "../../helpers/ImagesContext";
import useImages from "../../helpers/useImages";
import withNetlifyIdentity from "../../helpers/withNetlifyIdentity";

const content = flatten(
  merge(
    {},
    enAboutContent,
    enClientsContent,
    enServicesContent,
    enTeamContent,
    enContactContent,
    enValuesContent,
    nlAboutContent,
    nlClientsContent,
    nlServicesContent,
    nlTeamContent,
    nlContactContent,
    nlValuesContent,
    { common: { ...enCommonContent } },
    { common: { ...nlCommonContent } }
  )
);

const HomePage: React.FC = () => {
  const images = useImages();

  return (
    <IntlProvider
      defaultLocale={Locale.nl}
      locale={Locale.nl}
      messages={content}
    >
      <ThemeProvider theme={theme}>
        <ImagesContext.Provider value={images}>
          <GlobalHead locale={Locale.nl} />
          <GlobalStyle />
          <Home />
        </ImagesContext.Provider>
      </ThemeProvider>
    </IntlProvider>
  );
};

export default withNetlifyIdentity(HomePage);
